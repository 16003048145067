const axios = require("axios")
let i18n = require("@/i18n")
var config = {
    DKW_URL: "https://dkw-demokit.pubqlf.co.as8677.net/",
    WESHOP_URL: "https://dkw-demokit.pubqlf.co.as8677.net/",
    DKC_URL:  "https://dkc-demokit.pubqlf.co.as8677.net/",
    url_ws: "https://weshop-ws-v2-weshop.pubqlf.co.as8677.net/",
    PATH_IMG: "demonstration/get/demonstration/picture/",
    url_img:"https://weshop-ws-v2-weshop.pubqlf.co.as8677.net/catalog/product/image/",
    merchantId: "Démokit",
    pwaVersion: "1.3.3", //A changer dans service-worker.js également
    subjectMail: "Thank you for your purchase. This is your e-receipt",
    template: "iec_click_and_collect",
    senderMail: "no-reply-weshop@worldline.com",
    senderName: "WorldlineShop",
    mailsAdmins: "wldd.atosworldline@gmail.com", //separate by /
    appContext: "",
    context: "",
    loyaltyPourcent: 10
};

export function getConfig(){
    return config;
}

export function setAppContext(appContext){
    config.appContext = appContext;
}

export function setContext(context){
    config.context = context;
}

export function formatPrice(amount){
    /* eslint-disable */
    var locale = "en";
    amount = parseFloat(amount);
    switch(locale){
        case "en":
        case "fr":
            return Math.abs(amount).toFixed(2) +" €";
            break;
    }
}


export function sendMail(recipient, link, basketComplexId, lang){
    var path = "mail/send/ticket";
    var url = config.url_ws + path;
    let ticket = JSON.parse(localStorage.getItem("IEC_ticket"))

    var mailsAdmins = config.mailsAdmins;
    var subject = encodeURIComponent(config.subjectMail);
    var template= config.template;
    var senderMail = config.senderMail;
    var senderName = config.senderName;

    if(ticket.orderNumber){
        subject += " for your order #" + ticket.orderNumber;
    }

    var request = new XMLHttpRequest();
    var params = "recipient=" + recipient + "&mailsAdmins=" + mailsAdmins + "&link=" + link + "&subject=" + subject + "&template=" + template + "&basketComplexId=" + basketComplexId + "&senderMail=" + senderMail + "&senderName=" + senderName + "&lang=" + lang;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if (response.error == false) {
                    console.log("Email sent to " + recipient);
                }else{
                    console.error("Error sending mail");
                }
            }
        } else {

        }
    };
    request.open('POST', url, true);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}

export function checkToken() {
    var path = "/demonstration/checktoken";
    var url = config.DKW_URL + path;
    var request = new XMLHttpRequest();
    var demonstrationComplexId = localStorage.getItem("DKC_demoId");
    var customerComplexId = localStorage.getItem("DKC_complexId");
    var params = "?demonstrationComplexId=" + demonstrationComplexId + "&customerComplexId=" + customerComplexId;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if(response.error == true){
                    clearCache();
                }
            }else{
                app.appContext.showAlert(app.appContext.$t('errorCheckToken'),"error");
            }
        }
    };
    request.open('GET', url + params, true);
    request.send();
}

export function isNotNull(toCheck){
    if(toCheck != null && toCheck != "" && toCheck != "undefined" && toCheck != "-1"){
        return true;
    }else{
        return false;
    }
}

export function isStandalone(){
    var referer = localStorage.getItem("DKC_referer");
    var univers = localStorage.getItem("DKC_univers");
    var demoId = localStorage.getItem("DKC_demoId");
    var scanType = localStorage.getItem("DKC_shortScanType");
    var complexId = localStorage.getItem("DKC_complexId");
    var pathname = window.location.pathname.indexOf("p=");

    if(isNotNull(referer) && isNotNull(univers) && isNotNull(demoId) && isNotNull(scanType) && isNotNull(complexId) && !isNotNull(pathname)) {
        return true;
    }else{
        return false;
    }
}

export function clearCache(){
    var DKC_satisfaction = localStorage.getItem("DKC_satisfaction");
    localStorage.clear();
    if(isNotNull(DKC_satisfaction))
        localStorage.setItem("DKC_satisfaction", DKC_satisfaction);

    window.location.reload()
}

export function clearCacheExceptCustomer(self) {
    var DKC_demoId = localStorage.getItem("DKC_demoId");
    var DKC_univers = localStorage.getItem("DKC_univers");
    var DKC_complexId = localStorage.getItem("DKC_complexId");
    var DKC_customerEmail = localStorage.getItem("DKC_customerEmail");
    var DKC_firstname = localStorage.getItem("DKC_firstname");
    var IEC_lang = localStorage.getItem("IEC_lang");
    var DKC_lastname = localStorage.getItem("DKC_lastname");
    var DKC_fuel_token = localStorage.getItem("DKC_fuel_token");
    var DKC_fashion_token = localStorage.getItem("DKC_fashion_token");
    var DKC_food_token = localStorage.getItem("DKC_food_token");
    var IEC_deviceType = localStorage.getItem("IEC_deviceType");

    clearCache();

    if(isNotNull(DKC_demoId))
        localStorage.setItem("DKC_demoId", DKC_demoId);

    if(isNotNull(DKC_univers))
        localStorage.setItem("DKC_univers", DKC_univers);

    if(isNotNull(DKC_complexId))
        localStorage.setItem("DKC_complexId", DKC_complexId);

    if(isNotNull(DKC_customerEmail))
        localStorage.setItem("DKC_customerEmail", DKC_customerEmail);

    if(isNotNull(DKC_firstname))
        localStorage.setItem("DKC_firstname", DKC_firstname);

    if(isNotNull(IEC_lang))
        localStorage.setItem("IEC_lang", IEC_lang);

    if(isNotNull(DKC_lastname))
        localStorage.setItem("DKC_lastname", DKC_lastname);

    if(isNotNull(DKC_fuel_token))
        localStorage.setItem("DKC_fuel_token", DKC_fuel_token);

    if(isNotNull(DKC_fashion_token))
        localStorage.setItem("DKC_fashion_token", DKC_fashion_token);

    if(isNotNull(DKC_food_token))
        localStorage.setItem("DKC_food_token", DKC_food_token);

    if(isNotNull(IEC_deviceType))
        localStorage.setItem("IEC_deviceType", IEC_deviceType);

    if(isNotNull(config.appContext))
        config.appContext.productCrossSell = "";

    if(isNotNull(self) && !isNotNull(self.$route.query.ticketId)){
        self.$router.push('/need/scan?needParameters=true');
    }else if(isNotNull(self) && isNotNull(self.$route.query.ticketId)){
        window.location.reload()
    }
}

export function setLocalSatisfaction(type) {
    var all = JSON.parse(localStorage.getItem("DKC_satisfaction"));
    if(!isNotNull(all)){
        all = {};
    }
    all[type] = true;
    localStorage.setItem("DKC_satisfaction", JSON.stringify(all));
}

export function getLocalSatisfaction(type) {
    var all = localStorage.getItem("DKC_satisfaction");
    if(isNotNull(all)){
        var parse = JSON.parse(all);
        if(isNotNull(parse) && isNotNull(parse[type]))
        {
            return parse[type];
        }
    }
    return false;
}

export function sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
        if ((new Date().getTime() - start) > milliseconds){
        break;
        }
    }
}

export function generateFakeAddress(){
    let streetNb = parseInt(Math.random() * 100);

    let streetNames = [
        "Boulevard d'Alsace",
        "Rue Joseph Vernet",
        "Avenue Ferdinand de Lesseps",
        "Rue Goya",
        "Boulevard de Prague",
        "Rue du Général Ailleret",
        "Rue Sébastopol",
        "Avenue Voltaire",
        "Rue Bonnet",
        "Rue Pierre Motte",
        "Avenue Millies Lacroix",
        "Rue Saint Germain"
    ];
    let cities = [
        "59000 LILLE",
        "75000 PARIS",
        "59350 SECLIN",
        "44000 NANTES",
        "33000 BORDEAUX",
        "34000 MONTPELLIER",
        "29200 BREST",
        "69000 LYON",
        "64200 BIARRITZ",
        "28100 DREUX",
        "23000 GUÉRET",
        "78400 CHATOU"
    ];

    let randomStreetName = streetNames[parseInt(Math.random()*streetNames.length)];
    let randomCity = cities[parseInt(Math.random()*streetNames.length)];

    let fakeAddress = streetNb + " " + randomStreetName + " " + randomCity + " FRANCE"

    sessionStorage.setItem("DKC_fuel_pump_address", fakeAddress)

    return fakeAddress;
}

export function getSteps(){

    return [
        {
            id:1,
            label: i18n.default.t("basketStep"),
            icon: "icon-panier"
        },
        {
            id:2,
            label: i18n.default.t("paymentStep"),
            icon: "icon-carte"
        },
        {
            id:3,
            label:  i18n.default.t("confirmationStep"),
            icon: "icon-check"
        }
    ]
}

export function getNutriscore(productCode){
    let code = null
    if(productCode){
        code = productCode.replace("-code", "")
    }
    switch(code){
        case "chips" :
            return "C"
        case "chips-2" :
            return "B"
        case "chocolat" :
            return "D"
        case "cupcake" :
            return "C"
        case "eau" :
            return "A"
        case "sandwiche-1" :
            return "B"
        case "sandwiche-2" :
            return "B"
        case "soda" :
            return "E"
        default:
            return "A"
    }
}

export function sendSMS(message, recipient){
    const params = new URLSearchParams();
    params.append('phoneNumber', recipient);
    params.append('message', message.replace(/ /g,"+").replace(/&/g,"%26"));
    axios.post(config.DKW_URL + "/demonstration/sendSMS", params)
        .then(function (response) {
            if (response.status == 200) {
                console.log(response);
            } else {
                self.snackbar.show = true;
            }
        })
        .catch(function (error) {
            console.error("Error :" + error);
            self.snackbar.show = true;
        })
}

export function hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? result.slice(1,4).map(color => parseInt(color, 16)) : null;
}

export function urltoFile(url, filename, mimeType){
    return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
